import './shame';

const openUniqueModal = function(modal, button, e) {
  const uniqueId = $(button).attr('data-unique-id');

  e.preventDefault();
  modal.modal('show');
  modal.attr('data-unique-id', uniqueId);
};

$(document).on('turbolinks:load cocoon:after-insert', function(e, insertedItem) {
  const uniqueId = Math.random();
  const $insertedItem = $(insertedItem);

  $insertedItem.attr('data-unique-id', uniqueId);
  $insertedItem.find('.js-add-commission-account-button').attr('data-unique-id', uniqueId);

  $('.js-add-commission-account-button').on('click', function(e2) {
    const $commissionUserFormModal = $('#commission-account-form-modal');

    openUniqueModal($commissionUserFormModal, this, e2);
  });
});
