$(document).on('turbolinks:load cocoon:after-insert', function() {
  const $commissionInfoBonuses = $('.js-commission-info-bonus');

  $commissionInfoBonuses.on('change', function() {
    const $commissionInfoFields = $(this).closest('.js-commission-info-fields');
    const $commissionInfoExpirationDate = $commissionInfoFields.find('.js-commission-info-expiration-date');
    const $commissionInfoRate = $commissionInfoFields.find('.js-master-commission-account-rate');

    if ($(this).is(':checked')) {
      $commissionInfoExpirationDate.attr('readonly', false);
      $commissionInfoRate.attr('disabled', false);
    } else {
      $commissionInfoExpirationDate.attr('readonly', true);
      $commissionInfoExpirationDate.val('');
      $commissionInfoRate.val($commissionInfoRate.data('commission-account-rate'));
      $commissionInfoRate.attr('disabled', true);

    }
  });

  function setCommissionRate(commissionAccount) {
    const $commissionAccount = $(commissionAccount);
    const $commissionInfoFields = $commissionAccount.closest('.js-commission-info-fields');
    const $commissionInfoRate = $commissionInfoFields.find('.js-master-commission-account-rate');
    const $commissionInfoBonus = $commissionInfoFields.find('.js-commission-info-bonus');

    if (!$commissionInfoBonus.is(':checked')) {
      if ($commissionAccount.val()) {
        $.get('/admin/api/commission_accounts/' + $commissionAccount.val(), function(data) {
          $commissionInfoRate.val(data.current_master_commission_account_rate);
          $commissionInfoRate.data('commission-account-rate', data.current_master_commission_account_rate);
        });
      } else {
        $commissionInfoRate.val('');
        $commissionInfoRate.data('commission-account-rate', '');
      }
    }
  }

  const $multitrackCommissionAccount = $('.js-multitrack-commission-account-select');

  $multitrackCommissionAccount.each(function() {
    setCommissionRate($(this));
  });

  $multitrackCommissionAccount.on('change', function() {
    setCommissionRate($(this));
  });
});
