const fillImage = function(event, preview) {
  preview.show();

  const {files} = $(event.currentTarget)[0];

  if (files) {
    preview.find('img').remove();

    for (var i = 0; i < files.length; i++) {
      var file = files[i];

      var reader = new FileReader();
      reader.onload = function(e) {
        const imageBase64 = e.target.result;
        preview.append(`<img src="${imageBase64}" width='100px'/>`);
      };
      reader.readAsDataURL(file);
    }
  }
};

const findImagePreview = function(input) {
  const $input = $(input);

  if ($input.siblings('.js-image-preview').length > 0) {
    return $input.siblings('.js-image-preview');
  }

  return $input.parent().siblings('.js-image-preview');
};

const initImagePreview = () => {
  $('.js-image-file').change(function(event) {
    fillImage(event, findImagePreview(this));
  });
};

$(document).on('turbolinks:load unobtrusiveAjaxSuccess', initImagePreview);
