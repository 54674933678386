const setFileNames = function(fileInputField, fileNames) {
  const {files} = fileInputField;

  for (let i = 0; i < files.length; i++) {
    const filename = files[i].name;
    fileNames.append(`<p>${filename}</p>`);
  }
};

$(document).on('turbolinks:load cocoon:after-insert', function() {
  $('.js-multiple-upload-field').on('change', function() {
    const hiddenNestedInputs = $('.js-hidden-nested-inputs');
    const fileNames = $('.js-selected-file-names');

    hiddenNestedInputs.empty();
    fileNames.empty();

    setFileNames(this, fileNames);
  });
});
