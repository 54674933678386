$(document).on('turbolinks:load unobtrusiveAjaxSuccess', function() {
  const urlAnchor = window.location.hash;
  if (urlAnchor !== '') {
    $('a[href="' + urlAnchor + '"]').click();
  }

  $('.nav-tabs li a').on('click', function() {
    const anchor = $(this).attr('href');
    window.location.hash = anchor;
  });
});
