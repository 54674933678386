$(document).on('turbolinks:load cocoon:after-insert unobtrusiveAjaxSuccess', function() {
  const $songwriterSelect = $('.js-songwriter-select');
  const $songwriterModalSelect = $('.js-songwriter-modal-select');

  const ajaxParams = {
    url: '/admin/api/songwriters',
    dataType: 'json',
    type: 'GET',
    delay: 300,
    data: function(params) {
      return {
        query: params.term,
      };
    },
    processResults: function(data) {
      return {
        results: $.map(data, function(songwriter) {
          return {
            id: songwriter.id,
            text: songwriter.name,
          };
        }),
      };
    },
  };

  $songwriterSelect.select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 2,
    multiple: true,
    ajax: ajaxParams,
  });

  $songwriterModalSelect.each((index, element) => {
    const $select = $(element);

    $select.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Select',
      dropdownParent: $select.closest('.modal-dialog'),
      minimumInputLength: 2,
      ajax: ajaxParams,
    });
  });
});
