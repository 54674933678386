$(document).on('turbolinks:load', () => {
  $('.js-parse-chord-pro-data-btn').click(function(event) {
    const $divForParsedData = $('#js-parsed-chord-pro-data');
    const enteredData = $('#song_chord_pro_data').val();
    $.post('/admin/api/songs/parse_chord_pro_data', {data: enteredData}, function(data) {
      $divForParsedData.empty();
      data.sections.forEach((value) => {
        $divForParsedData.append('<h4> <b>Section: </b>' + value.title + '</h4>');
        $divForParsedData.append('<span style="white-space: pre-line">' + value.content + '</span>');
      });
    }).fail(function(response) {
      $divForParsedData.empty();
      $divForParsedData.append('<p class="text-danger">' + response.responseJSON.error_message + '</p>');
    });
  });
});
