$(document).on('turbolinks:load cocoon:after-insert unobtrusiveAjaxSuccess', function() {
  const $userSelect = $('.js-user-select');
  const $userModalSelect = $('.js-user-modal-select');

  const ajaxParams = {
    url: '/admin/api/users',
    dataType: 'json',
    type: 'GET',
    delay: 300,
    data(params) {
      return {
        query: params.term,
      };
    },
    processResults(data) {
      return {
        results: $.map(data, function(user) {
          return {
            id: user.id,
            text: user.username,
          };
        }),
      };
    },
  };

  $userSelect.select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 2,
    ajax: ajaxParams,
  });

  $userModalSelect.each((index, element) => {
    const $select = $(element);

    $select.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Select',
      dropdownParent: $select.closest('.modal-dialog'),
      minimumInputLength: 2,
      ajax: ajaxParams,
    });
  });
});
