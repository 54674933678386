$(document).on('turbolinks:load unobtrusiveAjaxSuccess', function() {
  const $payoutTotal = $('.js-payout-total');
  const $payoutDescription = $('.js-payout-description');
  const $quarterlyReports = $('.js-payout-quarter-checkbox');

  $quarterlyReports.on('change', function() {
    const selectedQuartersTotal = [];
    const selectedQuartersNames = [];
    $('.js-payout-quarter-checkbox:checked').each(function() {
      selectedQuartersTotal.push($(this).attr('data-total'));
      selectedQuartersNames.push($(this).attr('data-quarter-name'));
    });
    const totalSum = selectedQuartersTotal.reduce((sum, quarterTotal) => sum + parseFloat(quarterTotal), 0);
    const totalDescription = selectedQuartersNames.join(', ');
    $payoutTotal.val(totalSum).trigger('keyup');
    $payoutDescription.val(totalDescription).trigger('keyup');
  });
});
