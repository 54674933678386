$(document).on('turbolinks:load cocoon:after-insert', function() {
  $('.js-song-select').select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 2,
    ajax: {
      url: '/admin/api/songs',
      dataType: 'json',
      type: 'GET',
      delay: 300,
      data: function(params) {
        return {
          query: params.term,
        };
      },
      processResults: function(data) {
        return {
          results: $.map(data, function(song) {
            return {
              id: song.id,
              text: song.title,
            };
          }),
        };
      },
    },
  });
});
