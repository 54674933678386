$(document).on('turbolinks:load cocoon:after-insert', function() {
  const $agreementEffectiveDate = $('.js-agreement-effective-date');
  const $agreementTermYears = $('.js-agreement-term-years');
  const $agreementExpiryDate = $('.js-agreement-expiry-date');

  function setExpiryDate() {
    if ($agreementEffectiveDate.val() && $agreementTermYears.val()) {

      const agreementEffectiveDate = new Date($agreementEffectiveDate.val());
      const agreementExpiryYear = agreementEffectiveDate.getFullYear() + parseInt($agreementTermYears.val(), 10);
      const agreementExpiryDateTimestamp = agreementEffectiveDate.setFullYear(agreementExpiryYear);

      const agreementExpiryDate = new Date(agreementExpiryDateTimestamp);

      $agreementExpiryDate.datepicker('setDate', agreementExpiryDate);
    } else {
      $agreementExpiryDate.datepicker('setDate', '');
    }
  }

  $agreementTermYears.on('change', function() {
    setExpiryDate();
  });

  $agreementEffectiveDate.on('change', function() {
    setExpiryDate();
  });
});
