function initDecimalInputMask() {
  $('.js-price-mask').inputmask({
    alias: 'decimal',
    groupSeparator: ',',
    radixPoint: '.',
    digits: 2,
    allowMinus: false,
    allowPlus: false,
    autoGroup: true,
    rightAlign: false,
    prefix: '$ ',
    clearMaskOnLostFocus: false,
  });
}

function initNumericInputMask() {
  $('.js-integer-mask').inputmask({
    alias: 'numeric',
    digits: 0,
    allowMinus: false,
    allowPlus: false,
    rightAlign: false,
  });
}

function initSignedNumericInputMask() {
  $('.js-signed-integer-mask').inputmask({
    alias: 'numeric',
    digits: 0,
    allowMinus: true,
    allowPlus: false,
    rightAlign: false,
  });
}

function initPercentInputMask() {
  $('.js-percent-mask').inputmask({
    alias: 'decimal',
    radixPoint: '.',
    digits: 2,
    allowMinus: false,
    allowPlus: false,
    autoGroup: true,
    rightAlign: false,
    suffix: ' %',
    clearMaskOnLostFocus: false,
  });
}

function initHexColorInputMask() {
  $('.js-hex-color-mask').inputmask({
    alias: 'Regex',
    regex: '#[A-Fa-f0-9]{6}',
  });

  $('.js-bare-hex-color-mask').inputmask({
    alias: 'Regex',
    regex: '[A-Fa-f0-9]{6}',
  });
}

function initSubmitFormEvent() {
  $(document).on('submit ajax:before', function() {
    const $decimalInput = $('.js-price-mask, .js-percent-mask');
    if ($decimalInput.length > 0) {
      $decimalInput.inputmask('remove');
    }
  });
}

$(document).on('turbolinks:load cocoon:after-insert unobtrusiveAjaxSuccess', function() {
  initDecimalInputMask();
  initNumericInputMask();
  initSignedNumericInputMask();
  initPercentInputMask();
  initHexColorInputMask();
  initSubmitFormEvent();
});
