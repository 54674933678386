$(document).on('turbolinks:load unobtrusiveAjaxSuccess', function() {
  const $useIndustryStandardsCheckbox = $('.js-use-industry-standards-checkbox');

  function setIndustryValues(commissionRate) {
    const $commissionRate = $(commissionRate);

    $commissionRate.attr('readonly', 'readonly');
    $commissionRate.val($commissionRate.data('industry-value'));
  }
  function setSavedValues(commissionRate) {
    const $commissionRate = $(commissionRate);
    $commissionRate.removeAttr('readonly');
    $commissionRate.val($commissionRate.data('saved-value'));
  }

  $useIndustryStandardsCheckbox.each(function() {
    if ($(this).is(':checked')) {
      const $commissionRate = $(this).closest('.js-royalty-fields').find('.js-commission-rate');

      setIndustryValues($commissionRate);
    }
  });

  $useIndustryStandardsCheckbox.on('change', function() {
    const $commissionRate = $(this).closest('.js-royalty-fields').find('.js-commission-rate');
    if ($(this).is(':checked')) {
      setIndustryValues($commissionRate);
    } else {
      setSavedValues($commissionRate);
    }
  });
});
