export class Select2 {
  static init() {
    $(document).on('turbolinks:before-cache', () => {
      if (this.select2) {
        this._destroy();
      }
    });

    $(document).on('turbolinks:load cocoon:after-insert unobtrusiveAjaxSuccess', () => {
      this.select2 = $('.js-select');
      this.select2Modal = $('.js-select-modal');
      this._init();
      this._initModal();
    });
  }

  static initModal($element) {
    $element.each((index, element) => {
      const $select = $(element);

      $select.select2({
        width: '100%',
        allowClear: true,
        placeholder: 'Select',
        dropdownParent: $select.closest('.modal-dialog'),
      });
    });
  }

  static _init() {
    this.select2.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Select',
    });
  }

  static _initModal() {
    this.initModal(this.select2Modal);
  }

  static _destroy() {
    this.select2.select2('destroy');
    this.select2Modal.select2('destroy');
    this.select2 = null;
    this.select2Modal = null;
  }
}

$(document).on('select2:open', () => {
    let allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
    allFound[allFound.length - 1].focus();
});
