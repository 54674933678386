export class Pagination {
  static init() {
    $(document).on('turbolinks:load unobtrusiveAjaxSuccess', () => {
      this._init();
    });
  }

  static _init() {
    const $resultsContainer = $('.js-results-container');

    $('ul.js-pagination a').on('click', function() {
      $resultsContainer.addClass('sk-loading');
    });
  }
}
