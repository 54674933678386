$(document).on('turbolinks:load unobtrusiveAjaxSuccess', function() {
  const $commissionAmount = $('.js-commission-amount');
  const $recalculateMessage = $('.js-recalculate-message');

  const $commissionFields = $('.js-commission-product-price, .js-commission-rate, .js-commission-share, .js-commission-industry-minimum');

  $commissionFields.on('change', function() {
    $commissionAmount.val('');
    $recalculateMessage.text('RECALCULATE!');
  });
});
