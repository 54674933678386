$(document).on('turbolinks:load', () => {
  $('.js-generate-isrc-code-btn').click(function(event) {
    $.post('/admin/api/multitracks/generate_isrc_code/', function(data) {
      $('.js-isrc-code').val(data.isrc_code);
    });
  });

  $('.js-validate-isrc-code-btn').click(function(event) {
    const isrcCode = $('.js-isrc-code').val();
    const $validationMessage = $('.js-isrc-code-validation-message');
    const multitrackId = $('.js-isrc-code-validation-message').data('multitrackId');

    $.post('/admin/api/multitracks/' + multitrackId + '/validate_isrc_code', {isrcCode: isrcCode}, function(data) {
      if (data.valid) {
        $validationMessage.text('ISRC code is valid.');
        $validationMessage.removeClass('text-danger');
      } else {
        $validationMessage.text('ISRC is not unique.');
        $validationMessage.addClass('text-danger');
      }
    });
  });
});

$(document).on('turbolinks:load cocoon:after-insert', () => {
  $('.js-chord-pro-section-select').change(function(event) {
    const $chordProDataField = event.target.parentElement.nextSibling.children[1];
    $.get('/admin/api/chord_pro_sections/' + event.target.value, function(data) {
      $chordProDataField.value = data.chord_pro_data;
    });
  });
});
