$(document).on('turbolinks:load cocoon:after-insert', function() {
  $('.js-patch-select').select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 1,
    ajax: {
      url: '/admin/api/patches',
      dataType: 'json',
      type: 'GET',
      delay: 300,
      data(params) {
        return {
          query: params.term,
          // eslint-disable-next-line
          music_application_id: $('.js-music-application').val(),
        };
      },
      processResults(data) {
        return {
          results: $.map(data, function(patch) {
            return {
              id: patch.id,
              text: patch.title,
            };
          }),
        };
      },
    },
  });
});
