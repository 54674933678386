import debounce from 'lodash/debounce';

$(document).on('turbolinks:load', function() {
  const $searchFormText = $('.js-search-form input[type="text"]');
  const $searchFormSelect = $('.js-search-form select');
  const $resultsContainer = $('.js-results-container');
  const $checkbox = $('.js-search-form input[type="checkbox"]');

  $searchFormSelect.on('select2:select select2:unselect', function() {
    $(this).closest('form').find('.js-submit').trigger('click');
    $resultsContainer.addClass('sk-loading');
  });

  $checkbox.on('change', function() {
    $(this).closest('form').find('.js-submit').trigger('click');
  });

  $('.js-search-form').on('ajax:complete', function(event) {
    const a = $('<a>', {
      href: event.detail[0].responseURL,
    });
    history.pushState({turbolinks: {}}, '', a.prop('search'));
  });

  let lastValue = '';

  const textSearch = debounce(function(field) {
    const $field = $(field);
    const currentValue = $field.val();

    if (currentValue.length === 1 || (currentValue === lastValue && currentValue.length > 0)) {
      return;
    }

    lastValue = currentValue;
    $field.closest('form').find('.js-submit').trigger('click');
    $resultsContainer.addClass('sk-loading');
  }, 300);

  $searchFormText.on('keydown', function(e) {

    // prevent default when enter is clicked
    if (e.which === 13) {
      e.preventDefault();
    }

    textSearch(this);
  });

  // commission portal specific logic
  function setClassesAndSubmitForm(button) {
    const $button = $(button);
    $button.parent().find('button').removeClass('btn-primary').addClass('btn-default');
    $button.removeClass('btn-default').addClass('btn-primary');
    $button.closest('form').find('.js-submit').trigger('click');
    $resultsContainer.addClass('sk-loading');
  }

  const $payoutInfoField = $('.js-payout-method-input');

  const $allPaymenMethodstBtn = $('.js-all-payout-methods-btn');
  const $paypalBtn = $('.js-only-paypal-btn');
  const $checkBtn = $('.js-only-check-btn');
  const $achBtn = $('.js-only-ach-btn');

  const $allManagedStatusBtn = $('.js-all-managed-status-btn');
  const $managedManagedStatusBtn = $('.js-only-managed-managed-status-btn');
  const $acceptedManagedStatusBtn = $('.js-only-accepted-managed-status-btn');
  const $invitedManagedStatusBtn = $('.js-only-invited-managed-status-btn');
  const $notInvitedManagedStatusBtn = $('.js-only-not-invited-managed-status-btn');

  const $managedStatusField = $('.js-managed-status-input');

  $allPaymenMethodstBtn.on('click', function(e) {
    e.preventDefault();
    $payoutInfoField.val('');
    setClassesAndSubmitForm(this);
  });

  $paypalBtn.on('click', function(e) {
    e.preventDefault();
    $payoutInfoField.val('paypal');
    setClassesAndSubmitForm(this);
  });

  $checkBtn.on('click', function(e) {
    e.preventDefault();
    $payoutInfoField.val('check');
    setClassesAndSubmitForm(this);
  });

  $achBtn.on('click', function(e) {
    e.preventDefault();
    $payoutInfoField.val('ach');
    setClassesAndSubmitForm(this);
  });

  $allManagedStatusBtn.on('click', function(e) {
    e.preventDefault();
    $managedStatusField.val('');
    setClassesAndSubmitForm(this);
  });

  $managedManagedStatusBtn.on('click', function(e) {
    e.preventDefault();
    $managedStatusField.val('managed');
    setClassesAndSubmitForm(this);
  });

  $acceptedManagedStatusBtn.on('click', function(e) {
    e.preventDefault();
    $managedStatusField.val('invitation_accepted');
    setClassesAndSubmitForm(this);
  });

  $invitedManagedStatusBtn.on('click', function(e) {
    e.preventDefault();
    $managedStatusField.val('invited');
    setClassesAndSubmitForm(this);
  });

  $notInvitedManagedStatusBtn.on('click', function(e) {
    e.preventDefault();
    $managedStatusField.val('not_invited');
    setClassesAndSubmitForm(this);
  });

  const params = new URL(window.location.href).searchParams;

  const $payoutMethod = params.get('q[payout_info_preferred_payout_method_eq]');
  const $managedStatus = params.get('q[managed_status_eq]');

  if ($payoutMethod === 'paypal') {
    $paypalBtn.parent().find('button').removeClass('btn-primary').addClass('btn-default');
    $paypalBtn.removeClass('btn-default').addClass('btn-primary');
    $payoutInfoField.val('paypal');
  } else if ($payoutMethod === 'check') {
    $checkBtn.parent().find('button').removeClass('btn-primary').addClass('btn-default');
    $checkBtn.removeClass('btn-default').addClass('btn-primary');
    $payoutInfoField.val('check');
  }

  if ($managedStatus === 'managed') {
    $managedManagedStatusBtn.parent().find('button').removeClass('btn-primary').addClass('btn-default');
    $managedManagedStatusBtn.removeClass('btn-default').addClass('btn-primary');
    $managedStatusField.val('managed');
  } else if ($managedStatus === 'invitation_accepted') {
    $acceptedManagedStatusBtn.parent().find('button').removeClass('btn-primary').addClass('btn-default');
    $acceptedManagedStatusBtn.removeClass('btn-default').addClass('btn-primary');
    $managedStatusField.val('invitation_accepted');
  } else if ($managedStatus === 'invited') {
    $invitedManagedStatusBtn.parent().find('button').removeClass('btn-primary').addClass('btn-default');
    $invitedManagedStatusBtn.removeClass('btn-default').addClass('btn-primary');
    $managedStatusField.val('invited');
  } else if ($managedStatus === 'not_invited') {
    $notInvitedManagedStatusBtn.parent().find('button').removeClass('btn-primary').addClass('btn-default');
    $notInvitedManagedStatusBtn.removeClass('btn-default').addClass('btn-primary');
    $managedStatusField.val('not_managed');
  }
});
