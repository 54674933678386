$(document).on('turbolinks:load unobtrusiveAjaxSuccess', function() {
  const $artistSelect = $('.js-artist-select');
  const $artistModalSelect = $('.js-artist-modal-select');

  const ajaxParams = {
    url: '/admin/api/artists',
    dataType: 'json',
    type: 'GET',
    delay: 300,
    data: function(params) {
      return {
        query: params.term,
      };
    },
    processResults: function(data) {
      return {
        results: $.map(data, function(artist) {
          return {
            id: artist.id,
            text: artist.name,
          };
        }),
      };
    },
  };

  $artistSelect.select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 2,
    ajax: ajaxParams,
  });

  $artistModalSelect.each((index, element) => {
    const $select = $(element);

    $select.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Select',
      dropdownParent: $select.closest('.modal-dialog'),
      minimumInputLength: 2,
      ajax: ajaxParams,
    });
  });
});
