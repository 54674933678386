/**
 * This is a workaround for modals in Safari. In the case when modal form contains file upload field
 * and the file is not submitted, Safari is stuck with pending request.
 */
document.addEventListener('ajax:before', function(e) {
  var inputs = e.target.querySelectorAll('input[type="file"]:not([disabled])');
  inputs.forEach(function(input) {
    if (input.files.length > 0) {
      return;
    }
    input.setAttribute('data-safari-temp-disabled', 'true');
    input.setAttribute('disabled', '');
  });
});

document.addEventListener('ajax:beforeSend', function(e) {
  var inputs = e.target.querySelectorAll('input[type="file"][data-safari-temp-disabled]');
  inputs.forEach(function(input) {
    input.removeAttribute('data-safari-temp-disabled');
    input.removeAttribute('disabled');
  });
});

// used for confirmation modal for deleting nested objects
// if we used only data: { confirm: 'Are you sure?' } object would be deleted on cancel as well

$(document).delegate('.js-confirm-nested-delete', 'cocoon:before-remove', function(e, item, result) {
  const confirmation = confirm('Are you sure you want to delete this track?');

  if (confirmation) {
    return item.fadeOut('slow');
  }

  result.val = false;
  return result.val;
});
