import './navigation';
import {Select2} from './select2';
import './search';
import './inputmask';
import './multiple_upload';
import './image_preview';
import './modal';
import './form_tab_errors';
import './nested_form';
import {Pagination} from './pagination';
import './datepicker';
import './tabs';
import './hidden_columns';
import './commission_calculation';
import './payout_calculator';
import './tooltips';
import './sort';
import './commission_accounts';
import './commission_infos';
import './commission_account_agreements';
import './multitracks';
import './parse_chord_pro_data';
import './render_chord_chart_pdf';

import './ajax_select2/accounts';
import './ajax_select2/albums';
import './ajax_select2/artists';
import './ajax_select2/commission_accounts';
import './ajax_select2/frontend_users';
import './ajax_select2/multitracks';
import './ajax_select2/patches';
import './ajax_select2/patch_bundles';
import './ajax_select2/producers';
import './ajax_select2/products';
import './ajax_select2/songs';
import './ajax_select2/songwriters';
import './ajax_select2/track_types';
import './ajax_select2/users';
import './ajax_select2/artist_accounts';

Select2.init();
Pagination.init();
window.Loop = {
  Select2,
  Pagination,
};
