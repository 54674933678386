let $lastEightQuartersColumns;
let $lastFourQuartersColumns;
let $userPayoutInfoColumns;
let $lastPayoutInfoColumns;

let $noQuartersButton;
let $lastFourQuartersButton;
let $lastEightQuartersButton;

const quarters = {
  none: {
    queryAttribute: 'q[no_quarters_eq]',
    formField: '.js-no-quarters-input',
  },
  four: {
    queryAttribute: 'q[last_four_quarters_eq]',
    formField: '.js-four-quarters-input',
  },
  eight: {
    queryAttribute: 'q[last_eight_quarters_eq]',
    formField: '.js-eight-quarters-input',
  },
};

var currentQuarter = function() {
  const params = new URL(window.location.href).searchParams;
  for (const quarter in quarters) {
    if (params.get(quarters[quarter].queryAttribute) === 'true') {
      return quarter;
    }
  }
  return 'none';
};

var setQuartersDisplay = function(quarter) {
  if (quarter === 'eight') {
    $userPayoutInfoColumns.hide();
    $lastPayoutInfoColumns.hide();
    $lastEightQuartersColumns.show();
  } else if (quarter === 'four') {
    $userPayoutInfoColumns.hide();
    $lastPayoutInfoColumns.show();
    $lastEightQuartersColumns.hide();
    $lastFourQuartersColumns.show();
  } else if (quarter === 'none') {
    $userPayoutInfoColumns.show();
    $lastPayoutInfoColumns.show();
    $lastEightQuartersColumns.hide();
  }

  $(quarters[quarter].formField).val('true');
};

var setQuartersButtonToActive = function(button) {
  $(button).parent().find('button').removeClass('btn-primary').addClass('btn-default');
  $(button).removeClass('btn-default').addClass('btn-primary');
};


var updateQueryParameter = function(attributeName, value) {
  const url = new URL(window.location.href);
  url.searchParams.set(attributeName, value);
  history.pushState({turbolinks: {}}, '', url);
};

var setQuarterForm = function(button, quarter) {
  Object.keys(quarters).forEach(function(key) {
    const formField = $(quarters[key].formField);
    const attribute = quarters[key].queryAttribute;

    if (key === quarter) {
      formField.val('true');
      updateQueryParameter(attribute, 'true');
    } else {
      formField.val('');
      updateQueryParameter(attribute, '');
    }
  });

  const $button = $(button);
  const $resultsContainer = $('.js-results-container');

  $button.closest('form').find('.js-submit').trigger('click');
  $resultsContainer.addClass('sk-loading');
};

$(document).on('unobtrusiveAjaxSuccess', function() {
  $lastEightQuartersColumns = $('.js-last-eight-quarters');
  $lastFourQuartersColumns = $('.js-last-four-quarters');
  $userPayoutInfoColumns = $('.js-additional-info');
  $lastPayoutInfoColumns = $('.js-last-payout-info');

  $noQuartersButton = $('.js-no-quarters-btn');
  $lastFourQuartersButton = $('.js-last-four-quarters-btn');
  $lastEightQuartersButton = $('.js-last-eight-quarters-btn');

  $lastEightQuartersColumns.hide();

  setQuartersDisplay(currentQuarter());
});

$(document).on('turbolinks:load', function() {
  $lastEightQuartersColumns = $('.js-last-eight-quarters');
  $lastFourQuartersColumns = $('.js-last-four-quarters');
  $userPayoutInfoColumns = $('.js-additional-info');
  $lastPayoutInfoColumns = $('.js-last-payout-info');

  $noQuartersButton = $('.js-no-quarters-btn');
  $lastFourQuartersButton = $('.js-last-four-quarters-btn');
  $lastEightQuartersButton = $('.js-last-eight-quarters-btn');

  $lastEightQuartersColumns.hide();

  const quarterButtons = {
    none: $noQuartersButton,
    four: $lastFourQuartersButton,
    eight: $lastEightQuartersButton,
  };

  setQuartersButtonToActive(quarterButtons[currentQuarter()]);
  setQuartersDisplay(currentQuarter());

  $lastFourQuartersButton.on('click', function(e) {
    e.preventDefault();
    setQuartersButtonToActive(this);
    setQuartersDisplay('four');
    setQuarterForm(this, 'four');
  });

  $lastEightQuartersButton.on('click', function(e) {
    e.preventDefault();
    setQuartersButtonToActive(this);
    setQuartersDisplay('eight');
    setQuarterForm(this, 'eight');
  });

  $noQuartersButton.on('click', function(e) {
    e.preventDefault();
    setQuartersButtonToActive(this);
    setQuartersDisplay('none');
    setQuarterForm(this, 'none');
  });
});
