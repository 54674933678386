function getTabIdsWithErrors(errorFields) {
  const nonUniqueIds = errorFields.map(function() {
    return $(this).parents('.tab-pane').attr('id');
  }).toArray();

  const uniqueIds = Array.from(new Set(nonUniqueIds));

  return uniqueIds;
}

$(document).on('turbolinks:load unobtrusiveAjaxSuccess', function() {
  const $tabForm = $('.js-tab-form');
  const $tabFormSubmitButton = $('.js-btn-tab-form-submit');

  if ($tabForm.length > 0) {
    const $errorFields = $tabForm.find('.has-error');

    if ($errorFields.length > 0) {
      const tabWithErrorIds = getTabIdsWithErrors($errorFields);

      // open first tab that has errors
      $(`.nav-tabs a[href="#${tabWithErrorIds[0]}"]`).click();

      // add tab-with-error class to tabs with errors
      tabWithErrorIds.forEach(function(id) {
        $(`.nav-tabs a[href="#${id}"]`).parent('li').addClass('tab-with-error');
      });
    }

    $tabForm.find('.nav-tabs a').on('click', function() {
      if ($(this).data('type') === 'info-tab') {
        $tabFormSubmitButton.hide();
      } else {
        $tabFormSubmitButton.show();
      }
    });
  }
});
