function hideOrShowRemoveButton() {
  const $nestedForm = $('.js-nested-form');

  $nestedForm.each(function(index, element) {
    const $removeButton = $(element).find('.js-remove-nested-fields');

    if ($(element).find('.nested-fields:visible').length === 1) {
      $removeButton.hide();
    } else {
      $removeButton.show();
    }
  });
}

function hideDestroyedElements() {
  $('.remove_fields.existing.destroyed').closest('.nested-fields').hide();
}

$(document).on('turbolinks:load shown.bs.tab cocoon:after-remove cocoon:after-insert', function() {
  hideDestroyedElements();
  hideOrShowRemoveButton();
});
