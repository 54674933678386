import RailsUjs from '@rails/ujs';

$(document).on('turbolinks:load', () => {
  $('.js-render-chord-chart-PDF-btn').click(function(event) {
    event.preventDefault();
    const form = event.target.closest('form');
    const oldAction = form.action;
    const oldTarget = form.target;
    form.target = '_blank';
    form.action = event.target.formAction;
    form.dataset.remote = true;
    RailsUjs.fire(form, 'submit');
    form.dataset.remote = false;
    form.action = oldAction;
    form.target = oldTarget;
  });
});
