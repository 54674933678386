// Entry point for the build script in your package.json

/* eslint-disable */
import './jquery';
import 'bootstrap/js/transition';
import 'bootstrap/js/alert';
import 'bootstrap/js/button';
import 'bootstrap/js/carousel';
import 'bootstrap/js/collapse';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/modal';
import 'bootstrap/js/tooltip';
import 'bootstrap/js/popover';
import 'bootstrap/js/scrollspy';
import 'bootstrap/js/tab';
import 'bootstrap/js/affix';
import 'metismenu';
import 'jquery-slimscroll';
import 'jquery.inputmask/dist/jquery.inputmask.bundle';
import 'bootstrap-datepicker/js/bootstrap-datepicker';
import 'cocoon';
import select2 from 'select2/dist/js/select2.full';
import RailsUjs from '@rails/ujs';
import Turbolinks from 'turbolinks';

import './src';

RailsUjs.start();
Turbolinks.start();
select2($);
/* eslint-enable */
